<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 text-center">
        <div class="text-center">
          <router-link to="/productlist">
            <img src="../../assets/img/event/new_BN_700_pc.jpg" alt="" class="img-fluid"/>
          </router-link>
          <div class="text-start px-xl-3 px-xxl-6 mt-4">
            <p style="text-align: start;">注意事項：</p>
            <ul style="text-align: start;">
              <li class="mt-3">
                凡於Papawash官網消費50元可兌換一點，點數小數點以四捨五入計算。實際累計點數以Papawash系統為準。會員若因任何理由退還商品或服務，會員點數則會予以扣除。
              </li>
              <li class="mt-2">
                點數生效日於Papawash官網消費滿10天鑑賞期後，自動匯入該會員帳號。
              </li>
              <li class="mt-2">
                消費送點數:每消費50元可累積1點,每1點可同現金1元抵用。
              </li>
              <li class="mt-2">
                點數積點現折抵用:每筆訂單消費可使用點數抵用，點數抵用上限:
                <br />單筆訂單結帳金額500元以下，點數抵用上限100點。
                <br />單筆訂單結帳金額501~1000元，點數抵用上限250點。
                <br />單筆訂單結帳金額1001元以上，點數抵用上限為結帳金額之50%。
              </li>
              <li class="mt-2">
                會員點數累計期間：當年度1月1日起~至當年度12月31日止。
              </li>
              <li class="mt-2">
                會員點數抵用有效期限：當年度累計之點數須於次年度3月31日前抵用完畢，逾期點數將全數歸零，不可延展，不另行通知。
                成功抵用後之點數將會予以扣除，效期內剩餘點數可以繼續累計直到有效期限為止。
              </li>
              <li class="mt-2">
                特殊專案特惠活動商品，不適用折抵會員點數及會員消費再送點數活動。
              </li>
              <li class="mt-2">
                Papawash保留所有會員權益、相關活動及規定之更改權利；更改會於Papawash官網公告，恕不另行通知。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
